/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';


export interface ApiRequestCalculationGetPoDocTypePost$Params {
  purOrg?: string;
  vendorCode?: string;
  vendorCountry?: string;
  ioNumber?: string;
  materialGroup?: string;
  vendorRelatedCount?: number;
  isContract?: boolean;
  itemSource?: string;
  accountAssignmentCategory?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiRequestCalculationGetPoDocTypePost(http: HttpClient, rootUrl: string, params?: ApiRequestCalculationGetPoDocTypePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<string>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiRequestCalculationGetPoDocTypePost.PATH, 'post');
  if (params) {
    rb.query('purOrg', params.purOrg, {});
    rb.query('vendorCode', params.vendorCode, {});
    rb.query('vendorCountry', params.vendorCountry, {});
    rb.query('ioNumber', params.ioNumber, {});
    rb.query('materialGroup', params.materialGroup, {});
    rb.query('vendorRelatedCount', params.vendorRelatedCount, {});
    rb.query('isContract', params.isContract, {});
    rb.query('itemSource', params.itemSource, {});
    rb.query('accountAssignmentCategory', params.accountAssignmentCategory, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<string>;
    })
  );
}

apiRequestCalculationGetPoDocTypePost.PATH = '/api/request/calculation/GetPODocType';
